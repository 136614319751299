//Function used to add alert to form component
function showAlert(form, type, msg) {
    if ($(form).find(".alert").length > 0) {
        $(form)
            .find(".alert")
            .remove();
    }
    $(form).prepend('<span class="alert ' + type + '">' + msg + "</span>");
}

//Function used to add URL Param
function addUrlParam(param, value) {
    const urlParams = new URLSearchParams(
        window.location.search
    );
    urlParams.set(param, value);
    window.location.search = urlParams;
}

export {
    showAlert,
    addUrlParam
}
